import React, { useState, useEffect } from "react";
import axios from "axios";

function PrivacyPolicy() {
    const [form, setform] = useState([]);

    useEffect(() => {
        datas();
    }, []);

    const datas = () => {
        axios
            .post(
                "https://api.vrallude.com/v1/alludeapi/doctor/getPolicies",
                {},
                {}
            )
            .then(
                (res) => {
                    if (res.status === 200) {
                        setform(res.data.doctorcompanyPolicy);
                    }
                },
                (error) => {
                    if (error.response && error.response.status === 400) {
                        alert("not getting");
                    }
                }
            );
    };

    return (
        <div>
            {" "}
            <header id="site-header" class="header-footer-group">
                <div class="header-inner">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-12 col-md-12 header-left">
                                <div class="main-logo">
                                    <div class="site-logo faux-heading">
                                        <a href="/" class="custom-logo-link" rel="home">
                                            <img
                                                width="146"
                                                height="49"
                                                src="wp-content/uploads/2023/03/logo.png"
                                                className="custom-logo"
                                                alt="Quickl"
                                                decoding="async"
                                            />
                                        </a>
                                    </div>
                                    <button
                                        class="toggle nav-toggle mobile-nav-toggle"
                                        data-toggle-target=".menu-modal"
                                        data-toggle-body-class="showing-menu-modal"
                                        aria-expanded="false"
                                        data-set-focus=".close-nav-toggle"
                                    >
                                        <span class="toggle-inner">
                                            <span class="toggle-icon"></span>
                                            <span class="toggle-text"></span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <main id="site-content">
                <div class="container">
                    <article
                        class="post-3 page type-page status-publish hentry"
                        id="post-3"
                    >
                        <div class="post-inner thin ">
                            <div class="entry-content">

                                <div class="wpb_column vc_column_container vc_col-sm-12">
                                    <div class="vc_column-inner">
                                        <div class="wpb_wrapper">
                                            <h1
                                                style={{ color: "#ffff", textAlign: "center" }}
                                                class="vc_custom_heading text-white mb-0 vc_custom_1679553814785"
                                            >
                                               About Us
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="vc_row-full-width vc_clearfix"></div>
                            <div class="vc_row wpb_row vc_row-fluid page-content-sec">
                                <div class="wpb_column vc_column_container vc_col-sm-12">
                                    <div class="vc_column-inner">
                                        <div class="wpb_wrapper">
                                            <div class="wpb_text_column wpb_content_element ">
                                                <div class="wpb_wrapper">
                                                    <div
                                                        className="card"
                                                        style={{ borderRadius: "10px", marginTop: "30px" }}
                                                    >
                                                        {" "}
                                                        <div className="card-body m-3">
                                                            <div
                                                                className="pt-2"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: form.aboutUs,
                                                                }}
                                                            ></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="section-inner"></div>
                    </article>
                </div>
            </main>
        </div>
    );
}

export default PrivacyPolicy;
