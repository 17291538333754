import "./App.css";
// import Header from "./Components/Header";
import Main from "./Components/Main";
import Footer from "./Components/Footer";
// import Faqs from "./Components/Faqs";
import Termsandconditions from "./Components/Termsandconditions";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import { Routes, Route } from "react-router-dom";
import About from "./Components/About";

function App() {
  return (
    <div>
      <Routes>
        <Route exact path="/" element={<Main />} />
        {/* <Route exact path="/Faqs" element={<Faqs />} /> */}
        <Route
          exact
          path="/Termsandconditions"
          element={<Termsandconditions />}
        />
         <Route
          exact
          path="/aboutus"
          element={<About/>}
        />
        <Route exact path="/PrivacyPolicy" element={<PrivacyPolicy />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
