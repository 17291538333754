import React from "react";
import pic1 from '../assets/pic1.png';
import pic5 from '../assets/pic5.png';

import pic3 from '../assets/pic3.png';
import pic4 from '../assets/pic4.png';

function Main() {
  return (
    <div >
      <header id="site-header" className="header-footer-group">
        <div className="header-inner">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12 col-md-12 header-left">
                <div className="main-logo">
                  <div className="site-logo faux-heading">
                    <a
                      href="/"
                      className="custom-logo-link"
                      rel="home"
                      aria-current="page"
                    >
                      <img
                        // width="146"
                        // height="49"
                        src="wp-content/uploads/2023/03/logo.png"
                        className="custom-logo"
                        alt="Quickl"
                        decoding="async"
                      />
                      {/* <h2>Allude</h2> */}
                    </a>
                    <span className="screen-reader-text">Allude</span>
                  </div>
                  <button
                    className="toggle nav-toggle mobile-nav-toggle"
                    data-toggle-target=".menu-modal"
                    data-toggle-body-className="showing-menu-modal"
                    aria-expanded="false"
                    data-set-focus=".close-nav-toggle"
                  >
                    <span className="toggle-inner">
                      <span className="toggle-icon"></span>
                      <span className="toggle-text"></span>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div
        className="menu-modal cover-modal header-footer-group"
        data-modal-target-string=".menu-modal"
      >
        <div className="menu-modal-inner modal-inner">
          <div className="menu-wrapper section-inner">
            <div className="menu-top">
              <button
                className="toggle close-nav-toggle fill-children-current-color"
                data-toggle-target=".menu-modal"
                data-toggle-body-className="showing-menu-modal"
                data-set-focus=".menu-modal"
              >
                <span className="toggle-text">Close Menu</span>
                <svg
                  className="svg-icon"
                  aria-hidden="true"
                  role="img"
                  focusable="false"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                >
                  <polygon
                    fill=""
                    fill-rule="evenodd"
                    points="6.852 7.649 .399 1.195 1.445 .149 7.899 6.602 14.352 .149 15.399 1.195 8.945 7.649 15.399 14.102 14.352 15.149 7.899 8.695 1.445 15.149 .399 14.102"
                  />
                </svg>
              </button>

              <nav className="expanded-menu" aria-label="Expanded">
                <ul className="modal-menu reset-list-style">
                  <li
                    id="menu-item-22"
                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-22"
                  >
                    <div className="ancestor-wrapper">
                      <a href="#">Home</a>
                    </div>
                  </li>
                </ul>
              </nav>

              <nav className="mobile-menu" aria-label="Mobile">
                <ul className="modal-menu reset-list-style">
                  <li
                    id="menu-item-18"
                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-18"
                  >
                    <div className="ancestor-wrapper">
                      <a href="#">Home</a>
                    </div>
                  </li>
                </ul>
              </nav>
            </div>

            <div className="menu-bottom"></div>
          </div>
        </div>
      </div>
      <main id="site-content">
        <div className="containerfluid">
          <article
            className="post-31 page type-page status-publish hentry"
            id="post-31"
          >
            <div className="post-inner thin">
              <div className="entry-content">
                <div
                  data-vc-full-width="true"
                  data-vc-full-width-init="false"
                  className="vc_row wpb_row vc_row-fluid home-banner vc_custom_1679573669530 vc_row-has-fill vc_row-o-equal-height vc_row-o-content-middle vc_row-flex"
                >
                  <div className="homebanner-content wpb_column vc_column_container vc_col-sm-6">
                    <div className="vc_column-inner">
                      <div className="wpb_wrapper">
                        <h1
                          style={{ textAlign: "center" }}
                          className="vc_custom_heading banner-title" id="kls"
                        >
                          Revolutionize your Medical Practice with the Power of AI Clinical Assistant
                        </h1>
                        <div className="wpb_text_column wpb_content_element banner-text">
                          <div className="wpb_wrapper">
                            <p >
                              Unlock the potential of AI in your daily clinical practice.
                              Whether it's a common case or a rare complication, Allude is

                              always on hand to provide real-time guidance!
                            </p>
                          </div>
                        </div>
                        <h1
                          style={{ textAlign: "center" }}
                          className="vc_custom_heading banner-title" id="kls"
                        >
                          Get Your Allude Doctor App
                        </h1>

                        <div className="wpb_text_column wpb_content_element vc_custom_1680328674846">
                          <div className="wpb_wrapper">
                            <div className="ban-btn">
                              <a
                                href="https://play.google.com/store/apps/details?id=com.allude.alludeusr"
                                target="_blank"
                                rel="noopener"
                              >
                                <img
                                  decoding="async"
                                  src="wp-content/uploads/2023/03/ag.png"
                                />
                              </a>
                              <a
                                href="https://play.google.com/store/apps/details?id=com.allude.alludeusr"
                                target="_blank"
                                rel="noopener"
                              >
                                <img
                                  decoding="async"
                                  src="wp-content/uploads/2023/03/ai.png"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="wpb_column vc_column_container vc_col-sm-6">
                    <div className="vc_column-inner">
                      <div className="wpb_wrapper">
                        <div className="wpb_single_image wpb_content_element vc_align_center vc_custom_1679549517843">
                          <figure className="wpb_wrapper vc_figure">
                            <div className="vc_single_image-wrapper vc_box_border_grey">
                              <img
                                width="612"
                                height="582"
                                // src="wp-content/uploads/2023/03/hero_img.png"
                                src={pic1}
                                className="vc_single_image-img attachment-full"
                                alt=""
                                decoding="async"
                                title="hero_img"
                                fetchpriority="high"
                                //     srcset="
                                //   https://quickl.ai/wp-content/uploads/2023/03/hero_img.png         612w,
                                //   https://quickl.ai/wp-content/uploads/2023/03/hero_img-300x285.png 300w
                                // "
                                sizes="(max-width: 612px) 100vw, 612px"
                              />
                            </div>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                </div>
                <div className="vc_row-full-width vc_clearfix"></div>
                <div
                  data-vc-full-width="true"
                  data-vc-full-width-init="false"
                  className="vc_row wpb_row vc_row-fluid pt-pb-20 vc_custom_1679557199184 vc_row-o-equal-height vc_row-o-content-middle vc_row-flex"
                >
                  <div className="wpb_column vc_column_container vc_col-sm-6">
                    <div className="vc_column-inner vc_custom_1679560643364">
                      <div className="wpb_wrapper">
                        <div className="wpb_single_image wpb_content_element vc_align_left vc_custom_1679549701850">
                          <figure className="wpb_wrapper vc_figure">
                            <div className="vc_single_image-wrapper vc_box_border_grey">
                              <img
                                width="626"
                                height="595"
                                // src="wp-content/uploads/2023/03/ab.png"

                                src={pic5}
                                className="vc_single_image-img attachment-full"
                                alt=""
                                decoding="async"
                                title="ask_anything_image1"
                                //     srcset="
                                //   https://quickl.ai/wp-content/uploads/2023/03/ask_anything_image1.png         626w,
                                //   https://quickl.ai/wp-content/uploads/2023/03/ask_anything_image1-300x285.png 300w
                                // "
                                sizes="(max-width: 626px) 100vw, 626px"
                              />
                            </div>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pl-5 wpb_column vc_column_container vc_col-sm-6">
                    <div className="vc_column-inner vc_custom_1679560638736">
                      <div className="wpb_wrapper">
                        <h2 className="vc_custom_heading section-title round rigth-round" id="kls1">
                          Ask your ALLUDE anything !!!
                          From Complex clinical scenario to a

                          general candid chat!
                        </h2>
                        <div className="wpb_text_column wpb_content_element vc_custom_1679549980810 text-block">
                          <div className="wpb_wrapper">
                            <p>
                              Feel free to engage with your AI on any topic! Whether you're
                              dealing with intricate clinical situations that require detailed
                              explanations or simply looking for a casual, non-clinical chat.
                              Just ask away and explore a wide range of discussions!
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="appie-hero-area wpb_column vc_column_container vc_col-sm-12">
                    <div className="vc_column-inner">
                      <div className="wpb_wrapper">
                        <div className="vc_row wpb_row vc_inner vc_row-fluid">
                          <div className="position-static wpb_column vc_column_container vc_col-sm-12">
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <div className="wpb_single_image wpb_content_element vc_align_center showcase-shape-4">
                                  <figure className="wpb_wrapper vc_figure">
                                    <div className="vc_single_image-wrapper vc_box_border_grey">
                                      <img
                                        width="640"
                                        height="640"
                                        src="wp-content/uploads/2023/04/130341-live-chatbot.gif"
                                        className="vc_single_image-img attachment-full"
                                        alt=""
                                        decoding="async"
                                        title="130341-live-chatbot"
                                        loading="lazy"
                                      />
                                    </div>
                                  </figure>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="vc_row-full-width vc_clearfix"></div>
                <div
                  data-vc-full-width="true"
                  data-vc-full-width-init="false"
                  className="vc_row wpb_row vc_row-fluid pt-pb-20 vc_custom_1679557204017 vc_row-has-fill vc_row-o-equal-height vc_row-o-content-middle vc_row-flex"
                >
                  <div className="pr-5 wpb_column vc_column_container vc_col-sm-6">
                    <div className="vc_column-inner vc_custom_1679560653348">
                      <div className="wpb_wrapper">
                        <h2
                          style={{ color: "white", textAlign: "center" ,lineHeight:'38px'}}
                          className="vc_custom_heading section-title round left-round"
                        >
                          Simply begin utilizing the EMR and select
                          the ALLUDE assisted Rx option. That's all

                          there is to it!
                        </h2>
                        <div className="wpb_text_column wpb_content_element vc_custom_1679551836890 text-block white-text">
                          <div className="wpb_wrapper">
                            <p>
                              Allude processes the case details inputted and produces AI-
                              generated responses that can be directly used in prescriptions,

                              enhancing efficiency and reducing the likelihood of overlooking
                              critical details often missed by humans in everyday clinical practice.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="wpb_column vc_column_container vc_col-sm-6">
                    <div className="vc_column-inner vc_custom_1679560648203">
                      <div className="wpb_wrapper">
                        <div className="wpb_single_image wpb_content_element vc_align_left vc_custom_1679549975741">
                          <figure className="wpb_wrapper vc_figure">
                            <div className="vc_single_image-wrapper vc_box_border_grey">
                              <img
                                width="585"
                                height="595"
                                src={pic4}
                                className="vc_single_image-img attachment-full"
                                alt=""
                                decoding="async"
                                title="chat_with_image"
                                loading="lazy"
                                //     srcset="
                                //   https://quickl.ai/wp-content/uploads/2023/03/chat_with_image.png         585w,
                                //   https://quickl.ai/wp-content/uploads/2023/03/chat_with_image-295x300.png 295w
                                // "
                                sizes="(max-width: 585px) 100vw, 585px"
                              />
                            </div>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="vc_row-full-width vc_clearfix"></div>
                <div
                  data-vc-full-width="true"
                  data-vc-full-width-init="false"
                  className="vc_row wpb_row vc_row-fluid pt-pb-20 vc_custom_1679557217144 vc_row-o-equal-height vc_row-o-content-middle vc_row-flex"
                >
                  <div className="wpb_column vc_column_container vc_col-sm-6">
                    <div className="vc_column-inner vc_custom_1679560668787">
                      <div className="wpb_wrapper">
                        <div className="wpb_single_image wpb_content_element vc_align_left vc_custom_1679551853247">
                          <figure className="wpb_wrapper vc_figure">
                            <div className="vc_single_image-wrapper vc_box_border_grey">
                              <img
                                width="625"
                                height="595"
                                // src="wp-content/uploads/2023/03/jk.png"
                                src={pic3}
                                className="vc_single_image-img attachment-full"
                                alt=""
                                decoding="async"
                                title="generate_any_image"
                                loading="lazy"
                                //     srcset="
                                //   https://quickl.ai/wp-content/uploads/2023/03/generate_any_image.png         625w,
                                //   https://quickl.ai/wp-content/uploads/2023/03/generate_any_image-300x286.png 300w
                                // "
                                sizes="(max-width: 625px) 100vw, 625px"
                              />
                            </div>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pl-5 wpb_column vc_column_container vc_col-sm-6">
                    <div className="vc_column-inner vc_custom_1679560664139">
                      <div className="wpb_wrapper">
                        <h2 className="vc_custom_heading section-title round rigth-round" id="kls3">
                          Please feel free to modify or delete the AI's
                          prescription suggestions, or create your own
                          prescription independently of the AI's input.
                        </h2>
                        <div className="wpb_text_column wpb_content_element vc_custom_1679551803386 text-block">
                          <div className="wpb_wrapper">
                            <p>
                              The prescription can be effortlessly and accurately created and
                              delivered to the patient with just one click via WhatsApp, SMS, or
                              directly through the app, eliminating the inconvenience of lost

                              paperwork.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="vc_row-full-width vc_clearfix"></div>


                <div className="vc_row-full-width vc_clearfix"></div>
                <div
                  data-vc-full-width="true"
                  data-vc-full-width-init="false"
                  className="vc_row wpb_row vc_row-fluid home-banner vc_custom_1679573669530 vc_row-has-fill vc_row-o-equal-height vc_row-o-content-middle vc_row-flex"
                >
                  <div className="wpb_column vc_column_container vc_col-sm-12">
                    <div className="vc_column-inner vc_custom_1679560658874">
                      <div className="wpb_wrapper">
                       
                        {/* <div className="vc_row wpb_row vc_inner vc_row-fluid vc_custom_1679561510676 vc_row-o-equal-height vc_row-flex"> */}
                        <div className="vc_row wpb_row vc_row-fluid home-banner vc_custom_1679573669530 vc_row-has-fill vc_row-o-equal-height vc_row-o-content-middle vc_row-flex">
                          {/* <div className="sub-block wpb_column vc_column_container vc_col-sm-6">
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <div className="wpb_text_column wpb_content_element vc_custom_1679584644008">
                                  <div className="wpb_wrapper">
                                    <div className="sub-box">
                                      <h4 >Weekly</h4>
                                      <h3 >500</h3>
                                      <ul>
                                        <li>
                                          <strong>High Word Limit</strong> for
                                          Questions &amp; Answers
                                        </li>
                                        <li>
                                          <strong>Unlimited</strong> Questions
                                          &amp; Answers
                                        </li>
                                        <li>
                                          <strong>Ads Free</strong> experience
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="sub-block sub-block-midd wpb_column vc_column_container vc_col-sm-6">
                            <div className="vc_column-inner vc_custom_1679561499697">
                              <div className="wpb_wrapper">
                                <div className="wpb_single_image wpb_content_element vc_align_left off-img">
                                  <h2 className="wpb_heading wpb_singleimage_heading" >
                                    10% OFF
                                  </h2>
                                  <figure className="wpb_wrapper vc_figure">
                                    <div className="vc_single_image-wrapper vc_box_border_grey">
                                      <img
                                        width="159"
                                        height="62"
                                        src="wp-content/uploads/2023/03/ah.png"
                                        className="vc_single_image-img attachment-full"
                                        alt=""
                                        decoding="async"
                                        title="ah"
                                        loading="lazy"
                                      />
                                    </div>
                                  </figure>
                                </div>

                                <div className="wpb_text_column wpb_content_element vc_custom_1679574018248">
                                  <div className="wpb_wrapper">
                                    <div className="sub-box">
                                      <h4>Monthly</h4>
                                      <h3 >1000</h3>
                                      <ul>
                                        <li>
                                          <strong>High Word Limit</strong> for
                                          Questions &amp; Answers
                                        </li>
                                        <li>
                                          <strong>Unlimited</strong> Questions
                                          &amp; Answers
                                        </li>
                                        <li>
                                          <strong>Ads Free</strong> experience
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                          <div className="homebanner-content wpb_column vc_column_container vc_col-sm-6">
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <h1
                                  style={{ textAlign: "center" }}
                                  className="vc_custom_heading banner-title" id="kls2"
                                >
                                  Users also utilize the advantages of
                                  electronic medical records, AI
                                  assistant, appointment bookings and

                                  more
                                </h1>
                                <div className="wpb_text_column wpb_content_element banner-text">
                                  <div className="wpb_wrapper">
                                    <p >
                                      Users can sign up to book in-person or video consultations,
                                      manage prescriptions, and securely store medical records online.
                                      They can also chat with doctors, share files during consultations,

                                      and access an AI assistant
                                    </p>
                                  </div>
                                </div>
                                <h1
                                  style={{ textAlign: "center" }}
                                  className="vc_custom_heading banner-title" id="kls"
                                >
                                  Get Your Allude Doctor App
                                </h1>

                                <div className="wpb_text_column wpb_content_element vc_custom_1680328674846">
                                  <div className="wpb_wrapper">
                                    <div className="ban-btn">
                                      <a
                                        href="https://play.google.com/store/apps/details?id=com.allude.alludeusr"
                                        target="_blank"
                                        rel="noopener"
                                      >
                                        <img
                                          decoding="async"
                                          src="wp-content/uploads/2023/03/ag.png"
                                        />
                                      </a>
                                      <a
                                        href="https://play.google.com/store/apps/details?id=com.allude.alludeusr"
                                        target="_blank"
                                        rel="noopener"
                                      >
                                        <img
                                          decoding="async"
                                          src="wp-content/uploads/2023/03/ai.png"
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="wpb_column vc_column_container vc_col-sm-6">
                            <div className="vc_column-inner">
                              <div className="wpb_wrapper">
                                <div className="wpb_single_image wpb_content_element vc_align_center vc_custom_1679549517843">
                                  <figure className="wpb_wrapper vc_figure">
                                    <div className="vc_single_image-wrapper vc_box_border_grey">
                                      <img
                                        width="612"
                                        height="582"
                                        src={pic1}
                                        className="vc_single_image-img attachment-full"
                                        alt=""
                                        decoding="async"
                                        title="hero_img"
                                        fetchpriority="high"
                                        //     srcset="
                                        //   https://quickl.ai/wp-content/uploads/2023/03/hero_img.png         612w,
                                        //   https://quickl.ai/wp-content/uploads/2023/03/hero_img-300x285.png 300w
                                        // "
                                        sizes="(max-width: 612px) 100vw, 612px"
                                      />
                                    </div>
                                  </figure>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                       
                      </div>
                    </div>
                  </div>

                </div>
                <div className="vc_row-full-width vc_clearfix"></div>
              </div>
            </div>

            <div className="section-inner"></div>
          </article>
        </div>
      </main>

    </div>
  );
}

export default Main;
