import React from "react";
import pic2 from '../assets/pic2.png';
function Footer() {
  return (
    <div>
      <footer id="site-footer" class="site-footer">

        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <img src={pic2} style={{ height: '350px' }} />
            </div>
            <div class="col-md-6  ">
              <div class="contact-right-cont position-relative round round-right">
                <div class="footer1">
                  {/* <h2 class="text-white mb-3 text-center">Contact us</h2> */}
                  <button style={{border:'2px solid #a95dc9',borderRadius:'50px',padding:'5px 10px 5px 10px'}}>Contact us</button>
                  <ul className="text-center">
                    <li>
                      <a href="mailto:admin@alludeapp.com">
                        <i
                          class="fa fa-envelope"
                          aria-hidden="true"
                          style={{ paddingRight: "10px" }}
                        ></i>
                        admin@alludeapp.com
                      </a>
                    </li>

                    <li>
                      <a href="mailto:+91-9392948910">
                        <i
                          class="fa fa-phone-square"
                          aria-hidden="true"
                          style={{ paddingRight: "10px" }}
                        ></i>{" "}
                        +91-9392948910
                      </a>
                    </li>

                    <li>
                      <i
                        class="fa fa-map-marker"
                        aria-hidden="true"
                        style={{ paddingRight: "10px" }}
                      ></i>
                      SanathNagar,
                      Hyderabad - 500018
                    </li>
                  </ul>

                  <p className="text-center">
                    <a href="/aboutus"> About</a> |
                    <a href="/PrivacyPolicy"> Privacy Policy</a> |
                    <a href="/Termsandconditions"> Terms &amp; Conditions.</a>{" "}
                  </p>

                  <p className="text-center">
                    © 2024 VRAH Telehealth Services Private Limited
                  </p>

                  <ul class="social-icon text-center">
                    <li>
                      <a href="#" target="_blank">
                        <img
                          decoding="async"
                          src="wp-content/uploads/2023/04/fb.png"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <img
                          decoding="async"
                          src="wp-content/uploads/2023/04/insta.png"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <img
                          decoding="async"
                          src="wp-content/uploads/2023/04/in.png"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>


      </footer>
    </div>
  );
}

export default Footer;
